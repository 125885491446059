import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Reducers/authReducer";
import categoryReducer from "./Reducers/categoryReducer";
import productReducer from "./Reducers/productReducer";
import subcategoryReducer from "./Reducers/subcategoryReducer";
import registerReducer from "./Reducers/registerReducer";
import orderReducer from "./Reducers/orderReducer";
import merchantReducer from "./Reducers/merchantReducer";
import reportReducer from "./Reducers/reportReducer";
import riderReducer from "./Reducers/riderReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  product: productReducer,
  orders: orderReducer,
  merchant: merchantReducer,
  report: reportReducer,
  riders: riderReducer,
});

export default rootReducer;
