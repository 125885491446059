import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

// Async thunk to fetch riders
export const fetchRiders = createAsyncThunk(
  "rider/fetchRiders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/delivery/all_riders");
      return response.data.Riders;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to assign a rider to an order
export const assignRider = createAsyncThunk(
  "riders/assignRider",
  async ({ orderId, riderId, merchantId }, { rejectWithValue }) => {
    try {
      const response = await api.post("/delivery/assign_rider", {
        orderId,
        riderId,
        merchantId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to assign rider");
    }
  }
);

export const unassignRider = createAsyncThunk(
  "riders/unassignRider",
  async ({ orderId, merchantId }, { rejectWithValue }) => {
    try {
      const response = await api.post("/delivery/unassign_rider", {
        orderId,
        merchantId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Rider slice
const riderReducer = createSlice({
  name: "riders",
  initialState: {
    riders: [],
    assignSuccessMessage: null,
    assignErrorMessage: null,
    loader: false,
  },
  reducers: {
    clearMessages: (state) => {
      state.assignSuccessMessage = null;
      state.assignErrorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Riders
      .addCase(fetchRiders.pending, (state) => {
        state.loader = true;
        state.riders = [];
      })
      .addCase(fetchRiders.fulfilled, (state, action) => {
        state.loader = false;
        state.riders = action.payload;
      })
      .addCase(fetchRiders.rejected, (state, action) => {
        state.loader = false;
        state.assignErrorMessage = action.payload || "Failed to fetch riders";
      })

      // Assign Rider
      .addCase(assignRider.pending, (state) => {
        state.loader = true;
        state.assignSuccessMessage = null;
        state.assignErrorMessage = null;
      })
      .addCase(assignRider.fulfilled, (state, action) => {
        state.loader = false;
        if (action.payload?.status_code === 0) {
          state.assignSuccessMessage = action.payload.message;
        } else {
          state.assignErrorMessage =
            action.payload.message || "Assignment failed";
        }
      })
      .addCase(assignRider.rejected, (state, action) => {
        state.loader = false;
        state.assignErrorMessage = action.payload || "Failed to assign rider";
      })

      // Unassign Rider
      .addCase(unassignRider.pending, (state) => {
        state.loader = true;
        state.assignSuccessMessage = null;
        state.assignErrorMessage = null;
      })
      .addCase(unassignRider.fulfilled, (state, action) => {
        state.loader = false;
        if (action.payload?.status_code === 0) {
          state.assignSuccessMessage = action.payload.message;
        } else {
          state.assignErrorMessage =
            action.payload.message || "Failed to unassign rider";
        }
      })
      .addCase(unassignRider.rejected, (state, action) => {
        state.loader = false;
        state.assignErrorMessage = action.payload || "Failed to unassign rider";
      });
  },
});

// Export actions and reducer
export const { clearMessages } = riderReducer.actions;
export default riderReducer.reducer;
